import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import Logo from "../../assets/download.jpeg"; // Import your logo image

const SplashScreen = () => {
  const [text, setText] = useState("");
  const [index, setIndex] = useState(0);

  const textAnimation = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  useEffect(() => {
    const fullText = "nookadelic";

    if (index < fullText.length) {
      setTimeout(() => {
        setText(fullText.slice(0, index + 1));
        setIndex((prevIndex) => prevIndex + 1);
      }, 100); // Adjust the delay as needed
    }
  }, [index]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#000", // Set background color to black
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999, // Ensure it's on top of other content
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column", // Center elements vertically on mobile
          textAlign: "center", // Center text horizontally on mobile
        }}
      >
        <animated.img
          src={Logo}
          alt="Logo"
          style={{ width: "200px", height: "200px", marginBottom: "16px" }}
        />
        <animated.div
          style={{
            color: "#fff",
            fontSize: "36px", // Set the font size to big
            fontFamily: "sans-serif", // Use sans-serif font
            fontWeight: "bold", // Make it bold
            ...textAnimation, // Apply animation to the text container
          }}
        >
          {text}
        </animated.div>
      </div>
    </div>
  );
};

export default SplashScreen;
