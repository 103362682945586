import React, { useEffect, useState } from "react";
import Header from "./Header";
import sss from "../assets/sss.jpg";
import bgs from "../assets/bgs.jpg";
import Portfolio from "./Portfolio";
import About from "./About";
import Jasa from "./Jasa";
import ContactUs from "./ContactForm";
import { useSpring, animated, config } from "react-spring";

const Dashboard = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backgroundScale = useSpring({
    transform: `translateY(-${scrollY * 0.2}px)`, // Adjust the factor to control the movement speed
    config: config.gentle,
  });

  //   const sectionMove = useSpring({
  //     transform: `translateY(${scrollY * 0.3}px)`, // Adjust the factor to control the movement speed
  //     config: config.gentle,
  //   });

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  return (
    <div>
      <Header />
      <br />
     <animated.div className="bg-black" style={fadeIn}>
      <animated.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${bgs})`,
          ...backgroundScale,
        }}
      >
        <div className="col-span-1 flex items-center">
          <div className="group relative">
            <animated.img
              src={sss}
              alt="Logo"
              className="w-auto h-100 max-h-80 transform rotate-y-10 group-hover:rotate-y-0 transition-transform duration-300"
              style={fadeIn}git p
            />
          </div>
          {/* Other content here */}
        </div>

        <div className="col-span-1 md:col-span-0 flex items-center justify-left text-white">
          <div>
            <h2 className="text-3xl font-bold mb-4 font-cus">
              Nookadelic
            </h2>
            <p>
              Perusahaan Yang Bergerak Dalam Bidang Desain Interior Dan
              Furniture Custom..
            </p>
          </div>
        </div>
      </animated.div>

      {/* Updated grid layout for mobile */}
      <div className="bg-black p-4 shadow-md">
        <div id="about-section"></div>
        <br />
        <br />
        <br />
        <About />
        <div id="portfolio-section"></div>
        <br />
        <br />
        <br />
        <Portfolio />
        <div id="jasa-section"></div>
        <br />
        <br />
        <br />
        <Jasa />
        <div id="contact-section"></div>
        <br />
        <br />
        <br />
        <ContactUs />
      </div>
    </animated.div> 
    </div>
    
  );
};

export default Dashboard;
