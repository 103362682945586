import React from "react";

const About = () => {
  return (
    <section className="container mx-auto mt-8 bg-black">
      <h2 className="text-3xl font-semibold mb-4 text-white text-center">
        About Us
      </h2>
      <p className="text-white text-center">
        Welcome to our interior design firm. We are passionate about creating
        beautiful and functional spaces that inspire.
      </p>
      <br />
      <p className="text-white text-center">
        Phasellus non efficitur dolor. Cras sit amet magna eu est dictum
        scelerisque vel vitae tortor. Nulla facilisi. Etiam quis finibus ligula.
        Nam lacinia, enim a tempor tincidunt, ex lectus molestie ex, et placerat
        dolor libero eget dui. Proin laoreet condimentum sem, vitae rhoncus leo
        porttitor sit amet. Donec vel risus erat. Fusce sit amet lorem et turpis
        placerat volutpat ut at velit. Mauris a purus egestas, condimentum risus
        nec, sodales nisl.
      </p>
      {/* Add more about us content here */}
    </section>
  );
};

export default About;
