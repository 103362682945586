import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ccsgwtk",
        "template_zw8mita",
        form.current,
        "H0ft61hnbWo09XoKq"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="mx-auto mt-8 bg-black p-4">
      <h2 className="text-3xl font-semibold mb-4 text-white text-center">
        Contact us
      </h2>
      <form
        ref={form}
        onSubmit={sendEmail}
        className="max-w-md mx-auto mt-8 bg-white p-4 rounded-lg shadow-md"
      >
        <div className="mb-4">
          <label
            htmlFor="user_name"
            className="text-gray-800 block font-bold mb-2"
          >
            Name
          </label>
          <input
            type="text"
            id="user_name"
            name="user_name"
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="user_email"
            className="text-gray-800 block font-bold mb-2"
          >
            Email
          </label>
          <input
            type="email"
            name="from_name" 
            id="from_name"
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="message"
            className="text-gray-800 block font-bold mb-2"
          >
            Message
          </label>
          <textarea
            id="message"
            name="message"
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <input
            type="submit"
            value="Send"
            className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 cursor-pointer"
          />
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
