import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import HeaderAdmin from "./HeaderAdmin";

const BuatLaporan = () => {
  const [judul, setJudul] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [link, setlink] = useState("");

  const navigate = useNavigate();
  const saveData = async (e) => {
    e.preventDefault();
    await axios.post("https://api.nookadelic.com/submitData", {
      //the data
      judul: judul,
      deskripsi: deskripsi,
      link: link,
    });
    navigate("/021931230-isdaad0213-120");
  };

  return (
    <div className="flex-col mt-5">
      <div className="W-full">
        <HeaderAdmin />
        <div className="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow shadow-slate-300">
          <form onSubmit={saveData} className="my-10">
            <div className="flex flex-col">
              <div className="mb-5">
                <label className="font-bold text-slate-700">Judul</label>
                <input
                  type="text"
                  className="w-full py-3 mt-1 border border-slate-200 rounded-lg px=3 focus:outline-none focus:border-slate-500 hover:shadow"
                  placeholder="Judul Porto"
                  value={judul}
                  onChange={(e) => setJudul(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <label className="font-bold text-slate-700">Deskripsi</label>
                <input
                  type="text"
                  className="w-full py-3 mt-1 border border-slate-200 rounded-lg px=3 focus:outline-none focus:border-slate-500 hover:shadow"
                  placeholder="Deskripsi Porto"
                  value={deskripsi}
                  onChange={(e) => setDeskripsi(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <label className="font-bold text-slate-700">Link Foto</label>
                <input
                  type="text"
                  className="w-full py-3 mt-1 border border-slate-200 rounded-lg px=3 focus:outline-none focus:border-slate-500 hover:shadow"
                  placeholder="ex : https://xnxx.com/"
                  value={link}
                  onChange={(e) => setlink(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="w-full py-3 font-bold text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow"
              >
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BuatLaporan;
